import React from "react";
import { ITimeframe } from "../../../entities/common";
import { cancellablePost, CancellablePromiseWrapper } from "../../../fetch-interceptor";
import { groupBy, toDateTime } from "../../utils/common";
import * as Metadata from "../../../entities/Metadata";
import { TimeTrackingEntryProject } from "../../../store/TimeTrackingStore";
import {
    GetSuggestionTypeByLabel,
    GetSuggestionTypeLabel,
    Office365TimeTrackingConnectorReaders
} from "../../../store/integration/PersonalOffice365Store";
import Link from "../../common/Link";
import TimeTrackingImportSuggestionsPanelBase, { BaseProps, ImportedSuggestionProps, ImportTimeTrackingSuggestionFilterBase, TimeTrackingSuggestionBase } from "./TimeTrackingImportSuggestionsPanelBase";
import EntityName from "../../views/list/columns/EntityName";
import { Icon } from "office-ui-fabric-react";
import { FilterAttribute } from "../../common/FilterAttributes/FilterAttribute";

type OwnProps = BaseProps<O365Suggestion> &
{
    projects: TimeTrackingEntryProject[];
    project?: TimeTrackingEntryProject;

    onDismiss: () => void;
    onImport: (entity: O365Suggestion, callback: () => void) => void;
    isEntityImported: (entity: O365Suggestion) => ImportedSuggestionProps | undefined;

    timeFrame: ITimeframe;
};

type Props = OwnProps;

type O365Suggestion = TimeTrackingSuggestionBase &
{
    reader: Office365TimeTrackingConnectorReaders;
};

type Filter = ImportTimeTrackingSuggestionFilterBase &
{
    readers?: string[];
};

export default function TimeTrackingImportFromM365(props: Props) {
    return (
        <TimeTrackingImportSuggestionsPanelBase
            {...props}
            onDismiss={() => {
                _loadSuggestionInProgress?.cancelTokenSource.cancel();
                props.onDismiss();
            }}
            loadSuggestions={_loadSuggestions}
            onRenderHeader={() => (
                <div className="ms-Panel-header">
                    <div className="ms-Panel-headerText">Import from Microsoft 365</div>
                    <div className="ms-Panel-secondaryText">
                        Import Microsoft 365 Activities as PPM Express Time Entries.
                        Please refer to <Link target="_blank" href="https://help.ppm.express/time-tracking/how-to-import-microsoft-365-activities-as-ppm-express-time-entries">the article</Link> for more details.
                    </div>
                </div>
            )}
            onRenderItemName={(entity: O365Suggestion) => (
                <EntityName
                    name={entity.name}
                    isTimelineView={true}
                    icon={
                        <div className={`logo ${Office365TimeTrackingConnectorReaders[entity.reader].toString()}`}>
                            <Icon iconName={_getIcon(entity.reader)} title={GetSuggestionTypeLabel(entity.reader)} />
                        </div>
                    }
                />
            )}

            onRenderCustomFilters={(filter, onFilterChange) => {
                return (
                    <FilterAttribute
                        key="type"
                        field={_fakeTypeField}
                        value={filter.readers}
                        onEditComplete={(newValue: string[]) => {
                            onFilterChange({ readers: newValue });
                        }}
                    />
                );
            }}
        />);
}

const _fakeTypeField: Metadata.Field = {
    id: "typeField",
    type: Metadata.FieldType.Text,
    name: "Type",
    label: "Type",
    group: Metadata.FieldGroup.SystemFields,
    isNative: false,
    isReadonly: false,
    isSystem: false,
    isCustom: false,
    settings: {
        editControl: "Dropdown",
        options: [
            Office365TimeTrackingConnectorReaders.CompletedOutlookCalendarEmails,
            Office365TimeTrackingConnectorReaders.OutlookTasks,
            Office365TimeTrackingConnectorReaders.SentOutlookEmails,
            Office365TimeTrackingConnectorReaders.OutlookCalendarEvents,
            Office365TimeTrackingConnectorReaders.TentativeCalendarEvents,
            Office365TimeTrackingConnectorReaders.RecentDocuments
        ].map(reader => {
            return { name: GetSuggestionTypeLabel(reader) };
        })
    },
};

let _loadSuggestionInProgress: CancellablePromiseWrapper<O365Suggestion[]>;

const _loadSuggestions = (timeFrame: ITimeframe, filter: Filter): Promise<O365Suggestion[]> => {

    let start = timeFrame.start;
    let end = timeFrame.end;

    if (filter.date?.from) {
        start = toDateTime(filter.date.from)!;

        if (filter.date.to) {
            end = toDateTime(filter.date.to)!;
        }
        else {
            end = start;
        }
    }
    else if (filter.date?.to) {
        start = toDateTime(filter.date.to)!;
        end = toDateTime(filter.date.to)!;
    }

    const postFilter: any = {
        start: start.getBeginOfDay().toISOString(),
        end: end.getEndOfDay().toISOString(),
        searchText: filter.searchText,
    };

    if (filter.readers?.length) {
        postFilter.readers = filter.readers.reduce(
            (prev, current) => prev | GetSuggestionTypeByLabel(current),
            Office365TimeTrackingConnectorReaders.None
        );
    }

    _loadSuggestionInProgress?.cancelTokenSource.cancel();

    return new Promise<O365Suggestion[]>((resolve, reject) => {
        
        _loadSuggestionInProgress = cancellablePost<O365Suggestion[]>(
            `api/timetracking/integration/office365/suggestions`,
            postFilter
        );

        _loadSuggestionInProgress.promise
            .then(data => {
                data.forEach(_ => {
                    _.date = new Date(_.date);
                });

                const byDay = groupBy(data, _ => `${_.date.toDateOnlyString()}_${_.name}_${_.reader}`);
                const suggestions: O365Suggestion[] = [];

                for (const [_, suggestionsByDate] of Object.entries<O365Suggestion[]>(byDay)) {
                    suggestions.push(suggestionsByDate[0]);
                }

                resolve(suggestions);
            })
            .catch(error => error.message !== "canceled" && reject(error));
    });
};

const _getIcon = (reader: Office365TimeTrackingConnectorReaders): string => {
    switch (reader) {
        case Office365TimeTrackingConnectorReaders.RecentDocuments:
            return "PPMXO365Document";
        case Office365TimeTrackingConnectorReaders.OutlookCalendarEvents:
            return "PPMXO365OutlookCalendarEvent";
        case Office365TimeTrackingConnectorReaders.TentativeCalendarEvents:
            return "PPMXO365TentativeCalendarEvent";
        case Office365TimeTrackingConnectorReaders.SentOutlookEmails:
            return "PPMXO365SentOutlookEmail";
        case Office365TimeTrackingConnectorReaders.CompletedOutlookCalendarEmails:
            return "PPMXO365CompletedOutlookEmail";
        case Office365TimeTrackingConnectorReaders.OutlookTasks:
            return "PPMXO365ToDoTask";

        default:
            throw new Error(`Not supported reader ${reader}`);
    }
};