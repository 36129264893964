import React, { useMemo, useState } from "react";
import ListMenu from "../../components/common/extensibleEntity/ListMenu";
import { EntityType } from "../../entities/common";
import FlagInput from "../common/inputs/FlagInput";
import { ApplicationState } from "../../store";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { UserState } from "../../store/User";
import { CommonOperations, contains } from "../../store/permissions";
import { IContextualMenuItem, Icon, } from "office-ui-fabric-react";
import LabellableFieldComponent from "../common/sectionsControl/uiControls/fieldsArea/LabellableFieldComponent";
import { notUndefined } from "../utils/common";
import { IsEnterpriseTimeTracker } from "../../store/TimeTrackingStore";
import ConfigureCustomFieldsPanel from "./ConfigureCustomFieldsPanel";
import { buildActionsMenuItemWithItems } from "../common/headerMenuItemBuilders";
import { ShowO365Connector, TimeType } from "./common";
import { TimeTrackingGlobalSettings } from "../../store/Tenant";

interface OwnProps {
    onAddTime: (type: TimeType) => void;

    mergeSmartSuggestions: boolean;
    setMergeSmartSuggestions: (newValue: boolean) => void;

    onFieldsUpdate: () => void;

    isReadonly: boolean;
    showMergeSmartSuggestions: boolean;
}

interface StateProps {
    user: UserState,
    isEnterpriseTimeTracker: boolean;
    showO365Connector: boolean;
    settings: TimeTrackingGlobalSettings;
}

type Props = OwnProps & StateProps & RouteComponentProps<{}>;

function TimeTrackingMenu(props: Props) {
    const { user, isEnterpriseTimeTracker, onAddTime,
        mergeSmartSuggestions, setMergeSmartSuggestions, isReadonly, onFieldsUpdate, showMergeSmartSuggestions, showO365Connector, settings } = props;

    const [isConfigureMode, setIsConfigureMode] = useState(false);

    const canViewCustomFields = contains(user.permissions.common, CommonOperations.TimeTrackingView)
        && contains(user.permissions.common, CommonOperations.ConfigurationView)
        && isEnterpriseTimeTracker;

    const farCommands: IContextualMenuItem[] = useMemo(() => {
        return [
            showMergeSmartSuggestions
                ? {
                    key: "mergeSmartSuggestions",
                    disabled: false,
                    onRender: () => (
                        <div className="smart-suggestion align-center">
                            <Icon iconName="PPMXSmartSuggestion" />
                  
                            <LabellableFieldComponent
                                label="Smart Suggestions"
                                description={"Use 'Smart Suggestions' to automatically add tasks assigned to you directly to your Timesheet, streamlining your workflow."} >
                        
                                <FlagInput value={mergeSmartSuggestions} onEditComplete={_ => setMergeSmartSuggestions(_!)} />
                            </LabellableFieldComponent>
                        </div>
                    )
                } : undefined,
            canViewCustomFields
                ? {
                    key: "manageFields",
                    name: "Configure Custom Fields",
                    iconProps: { iconName: "Settings" },
                    onClick: () => setIsConfigureMode(true)
                } : undefined
        ].filter(notUndefined)
    }, [canViewCustomFields, mergeSmartSuggestions, showMergeSmartSuggestions]);

    const commands = useMemo<IContextualMenuItem[]>(() => {
        const actionsItems: IContextualMenuItem[] = [
            showO365Connector
                ? {
                    key: "o365Suggestions",
                    name: `Import from M365`,
                    disabled: isReadonly,
                    iconProps: { iconName: "M365Logo" },
                    onClick: () => onAddTime(TimeType.O365)
                }
                : undefined,
            settings.enableExternalSuggestions
                ? {
                    key: "importExternalSuggestions",
                    name: "Import External Time",
                    disabled: isReadonly,
                    iconProps: { iconName: "PPMXSmartSuggestion" },
                    onClick: () => onAddTime(TimeType.ExternalSuggestion)
                }
                : undefined
        ].filter(notUndefined);

        return [
            {
                key: "addTask",
                name: `Import Task`,
                disabled: isReadonly,
                iconProps: { iconName: "PPMXImport" },
                onClick: () => onAddTime(TimeType.ProjectTask)
            },
            {
                key: "addNonWorking",
                name: "Add Administrative Time Entry",
                disabled: isReadonly,
                iconProps: { iconName: "Add" },
                onClick: () => onAddTime(TimeType.Administrative)
            },
            settings.enableReportingOnCustomTimeTrackingEntries
                ? {
                    key: "addCustomEvent",
                    name: "Add Custom Time Entry",
                    disabled: isReadonly,
                    iconProps: { iconName: "Add" },
                    onClick: () => onAddTime(TimeType.CustomEvent)
                }
                : undefined,
            actionsItems.length
                ? buildActionsMenuItemWithItems(actionsItems)
                : undefined
        ].filter(notUndefined);
    }, [showO365Connector, isReadonly]);
    
    return (
        <>
            <ListMenu
                entityType={EntityType.TimeTrackingEntry}
                commands={commands}
                farCommands={farCommands}
            />
            {
                isConfigureMode && <ConfigureCustomFieldsPanel
                    onFieldsUpdate={onFieldsUpdate}
                    onDismiss={() => setIsConfigureMode(false)} 
                />
            }
        </>
    );
}
function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps { 
    return {
        user: state.user,
        isEnterpriseTimeTracker: IsEnterpriseTimeTracker(state.tenant.subscription),
        showO365Connector: ShowO365Connector(state),
        settings: state.tenant.timeTracking.globalSettings
    };
}

export default withRouter<OwnProps>(connect(mapStateToProps)(TimeTrackingMenu));
