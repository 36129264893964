import React from "react";
import { connect } from "react-redux";
import { PPMFeatures, Subscription, TimeTrackingGlobalSettings } from "../../../store/Tenant";
import DropdownInput from "../../common/inputs/DropdownInput";
import LabellableComponent from "../../common/LabellableComponent";
import FlagInput from "../../common/inputs/FlagInput";
import DatePickerInput from "../../common/inputs/DatePickerInput";
import { toDate, toDateTime } from "../../utils/common";
import { ApplicationState } from "../../../store";
import { EnableCalculatingResourcePlanActualsBasedOnReportedTime, ShowResourcePlanSmartSuggestion } from "../../timeTracking/TimeTrackingGrid";

type OwnProps = {
    saveSettings: (settings: Partial<TimeTrackingGlobalSettings>, callback?: () => void) => void;
    settings: TimeTrackingGlobalSettings;
    canManage: boolean;
}

type StateProps = {
    showResourcePlanSmartSuggestion: boolean;
    enableCalculatingResourcePlanActualsBasedOnReportedTime: boolean;
    apiEnabled: boolean;
};

type Props = OwnProps & StateProps;

const TimeReportingSettings = (props: Props) => {
    const { settings, saveSettings, canManage, showResourcePlanSmartSuggestion, apiEnabled } = props;

    return (
        <>
            <div className="settings-fields-holder time-tracking-settings">

                <div className="settings-row">
                    <LabellableComponent className="field-container" label="Cut off date for the time reporting lock"
                        description={`Please select a cut off date for the time reporting lock. All data in users’ timesheets will be locked for changes until this date.`}>
                    
                        <DatePickerInput value={toDateTime(settings.timeReportingLock)?.toDateOnlyString()}
                            onChanged={_ => {
                                saveSettings({
                                    timeReportingLock: toDate(_) || null
                                });
                            }}
                            readOnly={!canManage}
                        />
                    </LabellableComponent>
                </div>
            
                <div className="settings-row">

                    <LabellableComponent className="field-container" label="Enable Assignment-Based Smart Suggestions"
                        description={`Define if users in your PPM Express tenant can use the Smart Suggestions option, which automatically adds tasks assigned to them, directly to their Timesheet. This option can be individually turned off at user’s My Time page.`}>
                    
                        <FlagInput value={settings.enabledAssignmentSmartSuggestions}
                            onChanged={_ => saveSettings({
                                enabledAssignmentSmartSuggestions: _
                            })}
                            readOnly={!canManage}
                        />
                    </LabellableComponent>

                    {showResourcePlanSmartSuggestion &&
                    
                        <LabellableComponent className="field-container" label="Enable Resource Plan Smart Suggestions"
                            description={`Define if users in your PPM Express tenant can use the Smart Suggestions option, which automatically adds Projects they have been added to in the Resource Plans, directly to their timesheet. This option can be individually turned off at user’s My Time page.`}>
                    
                            <FlagInput value={settings.enablaResourcePlanSmartSuggestion}
                                onChanged={_ => saveSettings({
                                    enablaResourcePlanSmartSuggestion: _
                                })}
                                readOnly={!canManage}
                            />
                        </LabellableComponent>
                    }
                    
                    <LabellableComponent className="field-container" label="Import from Microsoft 365"
                        description={`Define if users in your PPM Express tenant can import the Time Entries from Microsoft 365 to their Timesheets. This option allows establishing connection to user’s M365 to analyze certain user activities and suggest possible time entries to the user. `}>
                    
                        <FlagInput value={settings.enableO365Connector}
                            onChanged={_ => saveSettings({
                                enableO365Connector: _
                            })}
                            readOnly={!canManage}
                        />
                    </LabellableComponent>

                    {apiEnabled &&
                    
                        <LabellableComponent className="field-container" label="Import External Time"
                            description={`dummy text`}>
                    
                            <FlagInput value={settings.enableExternalSuggestions}
                                onChanged={_ => saveSettings({
                                    enableExternalSuggestions: _
                                })}
                                readOnly={!canManage}
                            />
                        </LabellableComponent>
                    }

                </div>

                <div className="settings-row">
                    <LabellableComponent className="field-container" label="Time Entry Minimum Duration"
                        description={`Select the minimum duration for a time entry for all users in your PPM Express tenant. If a user enters a time less than this specified minimum, the value will be automatically rounded up.`}>
               
                        <DropdownInput
                            value={settings.minReportingDurationMinutes}
                            readOnly={!canManage}
                            inputProps={{
                                options: [
                                    {
                                        key: 15,
                                        text: "15 minutes"
                                    },
                                    {
                                        key: 30,
                                        text: "30 minutes"
                                    },
                                    {
                                        key: 60,
                                        text: "60 minutes"
                                    }
                                ]
                            }}
                            onChanged={(_) => {
                                saveSettings({
                                    minReportingDurationMinutes: _
                                });
                            }}
                        />
                    </LabellableComponent>
                </div>

                <div className="settings-row">
                    <LabellableComponent className="field-container" label="Time Reporting Increment"
                        description={`This setting specifies the unit that all time entries must be in multiples of. For instance, if the increment is set to 15 minutes, all time entries must be in multiples of 15 minutes. This setting ensures consistency and simplifies the aggregation and reporting of time data.`}>
                        <DropdownInput
                            value={settings.reportingIncrementMinutes}
                            readOnly={!canManage}
                            inputProps={{
                                options: [
                                    {
                                        key: 1,
                                        text: "1 minute"
                                    },
                                    {
                                        key: 5,
                                        text: "5 minutes"
                                    },
                                    {
                                        key: 10,
                                        text: "10 minutes"
                                    },
                                    {
                                        key: 15,
                                        text: "15 minutes"
                                    }
                                ]
                            }}
                            onChanged={(_) => {
                                saveSettings({
                                    reportingIncrementMinutes: _
                                });
                            }}
                        />
                    </LabellableComponent>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (_: ApplicationState): StateProps => ({
    showResourcePlanSmartSuggestion: ShowResourcePlanSmartSuggestion(_.tenant),
    enableCalculatingResourcePlanActualsBasedOnReportedTime: EnableCalculatingResourcePlanActualsBasedOnReportedTime(_.tenant),
    apiEnabled: Subscription.contains(_.tenant.subscription, PPMFeatures.API)
});


export default connect(mapStateToProps)(TimeReportingSettings);
